import { enUS, es } from 'date-fns/locale'
import { i18n } from '@lingui/core'

const locales = {
  en: enUS,
  es: es,
}

export const addDays = (date, days) => {
  var result = new Date(date)
  result.setDate(result.getDate() + days)
  return result
}

export const getDateFnsLocale = () => {
  if (i18n?.locale && Object.prototype.hasOwnProperty.call(locales, i18n.locale)) {
    return locales[i18n.locale]
  } else {
    return locales['es']
  }
}

// Remember that the month is 0-based so February is actually 1.
export const isValidDate = (year, month, day) => {
  var d = new Date(year, month, day)
  if (d) {
    return d?.getFullYear() === parseInt(year) && d?.getMonth() === parseInt(month) && d?.getDate() === parseInt(day)
  } else {
    return false
  }
}
